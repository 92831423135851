* {
  outline: none;
}

body, #root {
  background-color: #FFF8F4 !important;
}

.main {
  margin: 15vh auto 0;
  width: 100%;
  max-width: 800px;
  text-align: center;

  .row {
    max-width: 100vh;
    min-width: 500px;
    margin: 0 auto;
  }

  @media screen and (max-height: 800px) and (min-width: 1000px) {
    margin-top: 8vh;
  }
}

#wizard-toolbar {
  .toolbar {
    max-height: 8vh !important;
    min-height: 60px !important;

    a.wizard-desktop-logo {
      position: relative;
      height: 34px;
      max-height: 100%;
      top: 16px;
    }
  }
}

.btn-primary {
  min-width: 10vh;

  &:disabled {
    background-color: #E7E7E7 !important;
  }
}

h1 {
  font-size: 4vh !important;
  margin: 5vh 20px;
}

h3 {
  margin: 5vh 20px 0 !important;
  font-weight: normal !important;
  font-size: 2vh !important;
}

h2 {
  margin-bottom: 2vh;
}

.vehicle-reg-input {
  max-width: 300px;
  margin: 2vh auto;
  text-align: center;
  font-size: 3vh !important;
  background-color: #fff !important;
  height: 5vh !important;
  min-height: 40px;
  border-bottom: 2px solid #3B4365 !important;
  color: #3B4365 !important;
  font-weight: 400 !important;
  -webkit-text-fill-color: #3B4365 !important;
  opacity: 1 !important;
}

.dropdown-box {
  overflow: auto !important;
}

.message {
  max-width: none !important;
}

.margin-right {
  margin-right: 5vw;
}

.loading-spinner {
  margin: 30vh auto 10vh !important;
}

.icon {
  margin: 20vh auto 5vh;
  background-size: 100% 100%;
  height: 100px !important;
  width: 100px !important;
}

.warning_container {

  .aio-icon-excl_red{
    background-color: #f4f4f4;
    margin: 20vh auto 5vh;
    background-size: 100% 100%;
    height: 100px;
    width: 100px;
  }

}

.vehicle-reg-container {
  margin: 10vh auto 0;
  display: flex;
  justify-content: center;

  .padding_icon{
    display: flex;
    padding: 22px 22px 20px 22px;
    position: relative;
    height: fit-content;
    width: fit-content;
    justify-content: center;
    align-items: center;

    &.plain-vehicle-reg-container {
      padding: 0 22px 20px 22px;
    }

    .warning-icon{
      background-color: #f4f4f4;
      border-radius: 50%;
      position: absolute;
      top: 0;
      left: 0;
    }

    .valid-icon{
      background-color: #f4f4f4;
      border-radius: 50%;
      position: absolute;
      top: 0;
      left: 0;
    }

    .vehicle-reg {
      color: #000;
      padding: 0 6vh;
      height: fit-content;
      font-size: 6vh;
      background: #fff;
      border: 4px solid #000;
      border-radius: 8px;
    }
  }

  &.plain-vehicle-reg-container {
    margin-top: 0;
  }
}

.icon_warning_animated {
  background-image: url('../images/warningIconAnimated.svg');
}

.valid-icon {
  background-image: url("../icons/valid.svg");
  width: 65px;
  height: 65px;
}

.warning-icon {
  background-image: url("../icons/warning.svg");
  width: 65px;
  height: 65px;
}

.operator-footer {
  background-color: #FFF8F4 !important;
  width: 100%;
  padding: 0 20px 20px 20px;
  position: absolute;
  bottom: 0;
  left: 0;
  margin-top: 10vh;
  @media screen and (max-height: 700px) and (min-width: 1000px) {
    position: relative;
    height: 10vh
  }
}

.operator-footer img {
  max-height: 4vh;
}

.parking-validated {
  display: flex;
  flex-direction: column;
  max-width: 635px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.parking-validated-title {
  font-size: 40px;
  font-weight: bold;
  span {
    color: #EE662F;
  }
}

.parking-validated-subtitle {
  font-size: 18px;
  max-width: 550px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  span {
    font-weight: bold;
  }
}

.parking-validated-info {
  font-size: 20px;
}

.vehicle-characteristics {
  display: flex;
  justify-content: center;

  .vehicle-type {
    font-weight: 700;
    line-height: 1.1;
    font-size: 1.5rem;
  }

  .vehicle-color {
    margin: 0 7px 0 40px;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    border:2px solid #3B4365;
    display: inline-block;
  }

  .split-circle {
    background: linear-gradient(-45deg, #3B4365, #3B4365 49%, #FFFFFF 51% );
  }

}
