$header-color: #f7a379;
$background-color: #eaeaea;
$font-color: #3b4365;
$alternative-button-color: #3b4365;
$hover-color: #424898;

.keyboard-container {
  width: 100%;
  position: relative;
  margin: 5vh auto 0;
  font-family: 'Roboto', arial, sans-serif;

  button {
    font-size: 28px;
    border: solid 1px #3B4365;
    background-color: #FFFFFF;
    color: #3B4365;
    border-radius: 6px;
    box-shadow: 0 2px 3px 0 #D0D0D0;
    transition: all 0.2s ease;
    cursor: pointer;
    width: 9%;
    margin: .5%;
    position: relative;
    padding: 0;
    .btn-content {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 25%;
      left: 0;
    }
    &:after {
      content: "";
      display: block;
      padding-bottom: 100%;
    }
    &.primary-btn {
      background-color: $alternative-button-color;
      color: #fff;
      border-color: $alternative-button-color;
    }
    &.btn-widekey {
      width: 18%;
      &:after {
        padding-bottom: 50%;
      }
    }
    &:active, &:hover {
      background-color: $hover-color;
      border-color: $hover-color;
      color: #fff;
    }
  }
}
